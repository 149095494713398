// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.orderform-wrapper #gform_1 {
	margin-top: 4rem;
	font-size: 1.5rem;

	.gf_page_steps {
		margin: 0 0 3rem 0;
		text-align: center;
		border-bottom: none;

		.gf_step_active .gf_step_number {
			background: #cf971f;
			padding: 5px 10px;
			border-radius: 3px;
		}
	}

	#gform_page_1_1 .gform_fields {
		display: flex;
		flex-direction: row;
		margin-left: auto;
		margin-bottom: 0;
		padding-left: 0;
		list-style: none;
	}

	#field_1_1 .ginput_container{
		position: relative;
		padding-left: 2rem;
		&:before {
			left: -2rem;
			content: "The";
			position: absolute;
			font-size: 2rem;
		}
	}

	#field_1_5 .ginput_container{
		position: relative;
		padding-left: 3rem;
		&:before {
			left: .5rem;
			content: "in";
			position: absolute;
			font-size: 2rem;
		}
	}

	#gform_page_1_1 li label {	display: none; }
	#gform_page_1_2 #field_1_11 { width: 100%; }

	li {

		.ginput_container {

		  .gfield_select {
		  	min-width: 10rem;
		  	padding: 5px 20px 5px 4px;
				background: transparent;
				background-image: url(../images/arrow-down.png);
			  background-position: right;
			  background-repeat: no-repeat;
				color: $linen;
				border: none;
				border-bottom: 2px solid $gray-900;

			  -webkit-box-sizing: border-box;
			  -moz-box-sizing: border-box;
			  box-sizing: border-box;

				appearance:none;
			  -webkit-appearance: none;
			  -moz-appearance: none;

				&::-ms-expand {
				  isplay: none;
				}

				& option {
					color: $gray-900;
				}
			}

			input[type="text"] {
				min-width: 10rem;
				background: transparent;
				color: $linen;
				border: none;
				border-bottom: 2px solid $gray-900;

				&::placeholder {
					opacity: 1;
				}
			}

			input.button {
				@extend .btn;
				color: #FFF;
				background-color: $gray-800;
				text-transform: uppercase;
			}


			.chosen-container {
				background: url(../images/arrow-down.png) right no-repeat;
				a {
					padding: 11px 10px 11px 4px;
					border: none;
					border-bottom: 2px solid $gray-900;
					border-radius: 0;
					height: auto;
					color: $linen;
					background: transparent;
					box-shadow: none;

					& > div { display: none; }
				}

				.chosen-drop {
					font-size: .875rem;
				}

				.chosen-search input { color: $gray-700; }
			}
		}

		.gfield_error {
			margin-top: 0;
			background-color: transparent;
			border-top: none;
			border-bottom: none;
			padding-bottom: 0;
			padding-top: 0;
		}
		.validation_message { font-size: 1rem;}
	}


	#gform_page_1_1 {
		display: flex;
		justify-content: center;
	}

	.gform_page_footer {
		border-top: none;
		margin: 0;
		width: auto;

		.button {
			@extend .btn;
			@extend .btn-lg;
			cursor: pointer;
			color: #FFF;
			font-size: 1.5rem;
			text-transform: uppercase;
			background-color: $gray-800;
		}
	}

	#gform_page_1_2 label {	display: block; }


	@include media-breakpoint-down(sm) {
		margin-top: 2rem;
		font-size: 1.25rem;

		.gf_page_steps {
			margin: 0 0 2rem 0;
			.gf_step {
				width: auto;
				height: auto;
				margin: 0;
				display: inline-block;
			}
		}

		#gform_page_1_1, #gform_page_1_1 .gform_fields {
			flex-direction: column;
			justify-content: center;
		}

		#field_1_1 .ginput_container{
			padding-left: 3.25rem;
			&:before {
				left: -.5rem;
				content: "The";
				position: absolute;
				font-size: 2rem;
			}
		}

		#field_1_5 .ginput_container{
			position: relative;
			padding-left: 2rem;
			&:before {
				left: -.25rem;
				content: "in";
				position: absolute;
				font-size: 2rem;
			}
		}

		li .ginput_container .chosen-container a {
			padding: 0 10px 0 4px;
			line-height: 2.2;
		}

		.gform_page_footer {
			margin: 2rem 0 0 0;
		}

	}

	.partial_entry_warning {
		display: none !important;
	}

}

#gform_confirmation_wrapper_1 {
  margin-top: 4rem;
}

.orderform-wrapper .gform_confirmation_message {
	margin-top: 5rem;
	font-size: 2rem;
}


.orderform-wrapper .gform_wrapper {
	@include media-breakpoint-down(sm) {
		width: 95%;
	}
}