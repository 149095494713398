.sidebar .widget {
	margin-bottom: 1.25rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid $gray-200;

	h3 {
		color: $gray-600;
		font-size: 1.25rem;
		font-weight: bold;
	}
}