.frontpage {
	padding: 5rem 0;

	@include media-breakpoint-down(sm) {
		padding: 3rem 0;
	}
}

h1.intro {
	font-size: 3.5rem;
	font-weight: bold;
	text-transform: uppercase;
	text-align: center;
	@include media-breakpoint-down(sm) {
		font-size: 2.5rem;
	}
}

.frontpage-howitworks {
	background: $linen;

	.intro {
		font-family: 'Bangers', cursive;
		font-size: 5rem;

		@include media-breakpoint-down(xs) {
			font-size: 3rem;
		}
	}

	h2 {
		color: $blue;
		font-weight: bold;
	}

	.lead {
    font-size: 1.125rem;
	}
}

.frontpage-about {
	background: darken($linen, 4%);
	color: $gray-700;

	.intro {
		color: #333;
	}

	.lead {
		color: $blue;
		font-size: 1.5rem;
		@include media-breakpoint-down(sm) {
			font-size: 1.25rem;
		}
	}

	.text-columns {
		column-count: 2;
		column-gap: 3rem;
		font-size: 1.125rem;
		@include media-breakpoint-down(sm) {
			column-count: 1;
		}
	}
}

.frontpage-testimonials {
	background: #4c83a0 url('../images/bg-testimonials.jpg');
	color: #fff;
}

.frontpage-form {
	background: $orange;

	@include media-breakpoint-down(sm) {
		padding: 3rem 0 0;
	}
}


.category.card-columns {
	column-count: 5;

	@include media-breakpoint-down(lg) {
		column-count: 4;
	}
	@include media-breakpoint-down(md) {
		column-count: 3;
	}
	@include media-breakpoint-down(sm) {
		column-count: 2;
	}
	@include media-breakpoint-down(xs) {
		column-count: 1;
	}
}