@import '../../../bower_components/bootstrap/scss/functions';
@import '../../../bower_components/bootstrap/scss/variables';

//
// Color system
//

$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #616161 !default;
$gray-800: #39515d;
$gray-900: #212121;
$black:  #000 !default;

$blue:    #0a50a1;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #ebac26;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;
$linen:		#fceee3;


$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  linen: $linen,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800
) !default;

$theme-colors: (
  primary: $blue,
  secondary: $gray-800,
  success: $green,
  info: $cyan,
  warning: $orange,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
) !default;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// Fonts
$font-family-base: 'Lato', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif;

// Body
//
// Settings for the `<body>` element.

$body-bg:       $white !default;
$body-color:    $gray-900 !default;

// Links
//
// Style anchor elements.

$link-color:            lighten($gray-800, 15%);
$link-decoration:       none !default;
$link-hover-color:      $gray-800;
$link-hover-decoration: underline !default;

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1500px
);
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1440px
);
@include _assert-ascending($container-max-widths, "$container-max-widths");

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            false;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;

// Navbar

$navbar-dark-color:                 rgba($white,.5) !default;
$navbar-dark-hover-color:           rgba($white,.75) !default;
$navbar-dark-active-color:          rgba($white,1) !default;

$lead-font-weight: normal;