footer {

	.footer-header {
		min-height: 350px;
		background: $orange url("../images/footer_bg_top.png") bottom center repeat-x;
	}

	.footer-content {
		color: #D1D1D1;
		background: #39515D;

		.footer-icon {
			margin-right: 5px;
			vertical-align: middle;
		}
	}

	.footer-text {
		color: #99A8AD;
		background: #19242A;
		font-size: 0.85rem;
	}
}

