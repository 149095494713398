.blockquote {
	margin: 2rem 0;
	padding: 2rem 0;
	color: $blue;
	border-top: 2px solid $orange;
	border-bottom: 2px solid $orange;
	font-size: 1.375rem;
	font-style: italic;
}

.card-title a {
	color: $gray-200;
	font-weight: bold;
	&:hover { color: $gray-400; }
}