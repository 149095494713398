header {
  &.bg-header {
  	background-color: #28373F;
    background-image: url('../images/bg_header.png');
    background-attachment: fixed;
    background-position: center top;
    background-repeat: repeat-x;
    background-size: contain;
  }

  .navbar .navbar-brand {
  	margin-right: 0;
  }

  .navbar-expand-lg .navbar-nav {
    .nav-link {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    .nav-item:last-child {
      .nav-link { padding-right: 0; }
    }
  }

  .frontpage-intro {
  	margin: 4rem 0;

  	h1.intro {
  		margin-top: 1.5rem;
  		padding-top: 1.5rem;
			font-family: 'Bangers', cursive;
			font-size: 7rem;
		}

		p.lead {
			margin: .5rem 0 3rem;
			color: #FBB585;
			font-size: 2.25rem;
			font-weight: bold;
		}

		.btn-order {
			margin: 3.5rem 0 1rem;
			color: #2e414b;
			font-size: 1.5rem;
			font-weight: bold;
			text-transform: uppercase;
		}
  }


  .navbar-dark .navbar-toggler {
  	background: rgba(255,255,255,.2);
  }

  .navbar-collapse .navbar-nav {
  	align-items: right;
  	.nav-link {
  		padding-right: 0;
  	}
  }


  @include media-breakpoint-down(xs) {

  	.navbar {
			.header-logo {
				margin-right: -1rem;
				width: 75%;
			}
  	}

  	.frontpage-intro {
  		margin: 1.5rem 0;

  		h1.intro {
  			margin-top: 0;
  			padding-top: 0;
				font-size: 3rem;
			}

			p.lead {
				margin: .5rem 0 2rem;
				font-size: 1.5rem;
			}

			.btn-order {
				margin: 2.5rem 0 1rem;
			}
  	}

	}
}


